
    document.querySelector('section.slide-gallery').addEventListener('wheel', (e) => {
        if (e.deltaX == false) {    // Init shorizontal scroll
            var start = null
            var element = e.currentTarget
            var duration = 250
            var scrollAmount = e.wheelDelta > 0 ? e.wheelDelta : -e.wheelDelta;

            var animate = (timestamp) => {

                if (!start) start = timestamp;
                let progress = timestamp - start;
                element.scrollLeft += parseInt(direction + Math.min(scrollAmount / 100, scrollAmount))
                if (progress < duration) {
                    window.requestAnimationFrame(animate)
                }
            }
            let direction = e.wheelDelta > 0 ? '-' : '+';
            window.requestAnimationFrame(animate)
        }
    }, {passive: true})


    document.addEventListener("DOMContentLoaded", function () {
        var lazyImages = [].slice.call(document.querySelectorAll('.slide-gallery > img'))
        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let image = entry.target
                    image.src = image.dataset.src;
                    image.srcset = image.dataset.srcset;
                    lazyImageObserver.unobserve(image)
                }
            })
        })
        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage)
        })
    })
